.snackbar.invalid {
  position: absolute;
  width: var(--width);
  height: var(--height);
  background: linear-gradient(
    to right,
    rgba(255, 23, 23, 0.735),
    rgba(255, 0, 0, 0.809)
  );
  bottom: var(--bottom);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.snackbar.valid {
  position: absolute;
  width: var(--width);
  height: var(--height);
  background: linear-gradient(
    to right,
    rgba(23, 255, 93, 0.735),
    rgba(0, 255, 47, 0.809)
  );
  bottom: var(--bottom);
  display: flex;
  align-items: center;
  justify-content: center;
}

.snackbar-label.invalid {
  color: white;
  font-weight: 500;
  font-size: 11px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.snackbar-label.valid {
  color: rgb(39, 39, 39);
  font-weight: 500;
  font-size: 11px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
