.DayTimePicker {
  display: flex;
  flex-direction: column;
  user-select: none;
}

.daytimpick-holder {
  width: 100%;
  margin-bottom: var(--marBottom);
  display: flex;
  display: row;
  align-items: center;
}

.daytimepick-button {
  background-color: var(--isActive);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 70px;
  height: 30px;
  color: rgb(231, 231, 231);
  font-size: 11px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.daytimepick-button:hover {
  background-color: rgba(9, 181, 49, 0.919);
  color: rgb(255, 255, 255);
}

.daytimepick-label {
  color: rgb(108, 108, 108);
  font-size: 10px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
