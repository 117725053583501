.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  user-select: none;
}

.icon-button-unselected {
  background: none;
  border-radius: 8px;
  padding: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
}

.icon-button-unselected:hover {
  background-color: rgba(251, 137, 16, 0.072);
}

.icon-button-selected {
  background-color: var(--bgColor);
  border-radius: 8px;
  padding: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
}

.icon-btn-img {
  width: var(--iconSize);
  height: var(--iconSize);
  cursor: pointer;
}

.icon-btn-img-selected {
  width: var(--iconSize);
  height: var(--iconSize);
  cursor: pointer;
}

.icon-btn-img-unselected {
  width: var(--iconSize);
  height: var(--iconSize);
  filter: invert(0.6);
  cursor: pointer;
}