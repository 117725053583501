.Restaurants {
  height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.rest-menu-bar {
  height: 6%;
  width: 100%;
  border-bottom: 1px solid rgb(214, 223, 231);
  display: flex;
  flex-direction: row;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.rest-menu-bar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.rest-menu-bar {
  scrollbar-width: none;
}

.rest-menu-bar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.rest-menu-bar::-webkit-scrollbar-track {
  background-color: transparent;
}

.rest-menu-content {
  height: 94%;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.rest-menu-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.rest-menu-content {
  scrollbar-width: none;
}

.rest-menu-content::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.rest-menu-content::-webkit-scrollbar-track {
  background-color: transparent;
}


/* MAIN PAGE */
.Restaurants-main {
  height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  user-select: none;
  display: flex;
  flex-direction: row;
}

.rest-main-left-container {
  height: 100%;
  width: 40%;
  background-color: rgb(250, 250, 250);
  border-right: 1px solid rgb(214, 223, 231);
}

.rest-main-listview {
  padding-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rest-main-listitem-adapter-index-load {
  width: 5%;
  font-size: 8px;
  color: rgba(237, 237, 237, 0.559);
  background-color: rgba(237, 237, 237, 0.559);
}

.rest-main-listitem-adapter-index {
  width: 3%;
  font-size: 8px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.rest-main-listitem-adapter-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.rest-main-listitem-adapter-title-load {
  color: rgba(237, 237, 237, 0.827);
  background-color: rgba(237, 237, 237, 0.827);
  font-size: 12px;
  font-weight: 600;
}

.rest-main-listitem-adapter-title {
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.rest-main-listitem-adapter-subtitle {
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;   
  color: rgb(105, 105, 105);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.rest-main-container-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rest-main-listitem-adapter-dottitle {
  font-size: 10px;
  font-weight: 500;  
  color: rgb(105, 105, 105);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.rest-main-right-container {
  height: 98%;
  width: 56%;
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
  background-color: white;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.rest-main-right-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.rest-main-right-container {
  scrollbar-width: none;
}

.rest-main-right-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.rest-main-right-container::-webkit-scrollbar-track {
  background-color: transparent;
}


.rest-main-right-container-cont {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.AddRestaurant {
  height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.addrest-tools {
  position: relative;
  width: 94%;
  height: 50px;
  border-bottom: 1px solid rgb(214, 223, 231);
  transition: height 0.2s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
}

.addrest-tools-hide {
  position: relative;
  width: 94%;
  height: 0;
  border-bottom: 1px solid rgb(214, 223, 231);
  transition: height 0.2s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
  overflow: hidden;
}

.AddRestaurant-toast {
  width: 50%;
  left: 0;
}

.addrest-content {
  padding: 2%;
  position: relative;
  height: 96%;
  width: 96%;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.addrest-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.addrest-content::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.addrest-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.addrest-container-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.addrest-container-column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.addrest-location-map {
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
}

.addrest-label {
  color: rgb(80, 80, 80);
  font-size: 13px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.addrest-container {
  width: 100%;
  border: 1px solid rgb(214, 223, 231);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.addrest-in-container {
  display: flex;
  flex-direction: row;
}

.addrest-sublabel {
  color: rgb(75, 75, 75);
  font-size: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.listview-wrapper {
  height: 150px;
  border: 1px solid rgb(214, 223, 231);
  border-radius: 4px;
}

.horlistview-wrapper {
  border: 1px solid rgb(214, 223, 231);
  border-radius: 4px;
}

.listview-item-adapter {
  padding-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.listview-item-adapter-index-load {
  width: 5%;
  font-size: 8px;
  color: rgba(237, 237, 237, 0.559);
  background-color: rgba(237, 237, 237, 0.559);
}

.listview-item-adapter-index {
  width: 5%;
  font-size: 8px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.listview-item-adapter-card-load {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listview-item-adapter-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.listview-item-adapter-title-load {
  color: rgba(237, 237, 237, 0.827);
  background-color: rgba(237, 237, 237, 0.827);
  font-size: 12px;
  font-weight: 600;
}

.listview-item-adapter-title {
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.horlistview-item-adapter-title {
  font-size: 12px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}