.cir-avatar {
  width: 40px;
  height: 40px;
  margin: 5px;
  border-radius: 50%;
  display: flex;
  overflow: hidden;
  background-color: rgb(237, 100, 111);
  user-select: none;
}

.cir-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
