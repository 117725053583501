.label-button {
  width: var(--width);
  height: 100%;
  padding-left: 10px;
  padding-right: 10px; 
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
}

.label-btn-title {
  font-size: 11px;
  font-weight: 700;
  color: rgb(70, 125, 203);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-bottom: 12px;
  cursor: pointer;
  white-space: nowrap; 
}

.label-btn-title-unselected {
  font-size: 11px;
  font-weight: 700;
  color: rgb(134, 134, 134);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-bottom: 12px;
  cursor: pointer;
  white-space: nowrap; 
}

.label-btn-desgin {
  height: 1.5px;
  width: var(--width);
  background-color: rgb(85, 141, 221);
  cursor: pointer;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.label-btn-desgin-unselected {
  height: 1.5px;
  width: var(--width);
  background: none;
  cursor: pointer;
}
