.Foods {
  height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.foods-menu-bar {
  height: 6%;
  width: 100%;
  border-bottom: 1px solid rgb(214, 223, 231);
  display: flex;
  flex-direction: row;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.foods-menu-bar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.foods-menu-bar {
  scrollbar-width: none;
}

.foods-menu-bar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.foods-menu-bar::-webkit-scrollbar-track {
  background-color: transparent;
}

.foods-menu-content {
  height: 94%;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.foods-menu-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.foods-menu-content {
  scrollbar-width: none;
}

.foods-menu-content::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.foods-menu-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.foods-image-view {
  width: 100px;
  height: 100%;
  background-color: antiquewhite;
}

.food-container-column {
  display: flex;
  flex-direction: column;
}

.food-container-row {
  display: flex;
  flex-direction: row;
}