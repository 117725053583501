.ImageImport {
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.ImageImport-label {
  color: rgb(80, 80, 80);
  font-size: 13px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.ImageImport-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.ImageImport-group {
  border: 1px solid rgba(89, 89, 176, 0.513);
  height: 100px;
  width: 160px;
  border-radius: 4px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageImport-btn {
  width: 0;
  height: 0;
  display: none;
}

.ImageImport-img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.ImageImport-icon {
  position: absolute;
}
