.textField-label {
  color: rgb(80, 80, 80);
  user-select: none;
  font-size: smaller;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input-label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.input-field {
  box-sizing: border-box;
  width: 230px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 300px) {
  .input-field {
    width: 100%;
  }
}

.input-field:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.input-field.valid:focus {
  border-color: #08a200;
  box-shadow: 0 0 5px rgba(0, 255, 34, 0.5);
}

.input-field.invalid:focus {
  border-color: #ff3300;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

.input-field:hover {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.input-field::placeholder {
  font-size: 12px;
  color: #999;
}

.input-field::-webkit-input-placeholder {
  font-size: 12px;
  color: #999;
}

.input-field::-moz-placeholder {
  font-size: 12px;
  color: #999;
  opacity: 1;
}

.input-field:-ms-input-placeholder {
  font-size: 12px;
  color: #999;
}

.input-field::placeholder-shown {
  font-size: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.textField-title-label {
  color: rgb(80, 80, 80);
  font-size: 13px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.textField-indi {
  display: flex;
  flex-direction: column;
}
