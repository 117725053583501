.DotStatus {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 0.1px solid rgb(148, 148, 148);
  display: flex;
  overflow: hidden;
  background-color: var(--color);
  user-select: none;
}
