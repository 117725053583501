.listview-list {
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  scrollbar-width: none;
  user-select: none;
}

.listview-list::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.listview-list::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.listview-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.listview-adapter-item {
  height: var(--adapterheight);
  width: 100%;
  border-bottom: var(--bottomborder);
  background-color: var(--bg);
}

.listview-adapter-item-nohover {
  height: var(--adapterheight);
  width: 100%;
  border-bottom: var(--bottomborder);
  background-color: var(--bg);
}

.listview-adapter-item:hover {
  background-color: rgba(0, 119, 255, 0.05);
}

.listview-list-hor {
  height: 100%;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  scrollbar-width: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listview-list-hor::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.listview-list-hor::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.listview-list-hor::-webkit-scrollbar-track {
  background-color: transparent;
}

.listview-adapter-item-hor {
  height: var(--adapterheight);
  background-color: var(--bg);
}

.listview-adapter-item-hor-nohover {
  height: var(--adapterheight);
  background-color: var(--bg);
}

.listview-adapter-item-hor:hover {
  background-color: rgba(0, 119, 255, 0.05);
}
