.outline-button {
  color: var(--btnColor);
  width: var(--width);
  background: none;
  font-size: 10px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: none;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: var(--padTopBottom);
  padding-bottom: var(--padTopBottom);
  border: 1px solid var(--btnColor);
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
}

.outline-button:hover {
  box-shadow: 0 0 1.5px var(--btnColor);
}
