.otpField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  user-select: none;
}

.otpField-label {
  font-size: small;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input-label {
  margin-bottom: 0.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-input-field {
  width: 15px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.otp-input-field:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.otp-input-field.valid:focus {
  border-color: #08a200;
  box-shadow: 0 0 5px rgba(0, 255, 34, 0.5);
}

.otp-input-field.invalid:focus {
  border-color: #ff3300;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
}

.otp-input-field:hover {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.otp-input-field::placeholder {
  color: #999;
}

.otp-input-field::-webkit-input-placeholder {
  color: #999;
}

.otp-input-field::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.otp-input-field:-ms-input-placeholder {
  color: #999;
}

.otp-input-field::placeholder-shown {
  font-size: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.otp-input-field.index-0 {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.otp-input-field.index-5 {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
