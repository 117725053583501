.menu-button-selected {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  background-color: rgb(251, 137, 16);
  border-radius: 8px;
  padding: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  user-select: none;
}

.menu-button-unselected {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  border-radius: 8px;
  background: none;
  padding: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  user-select: none;
}

.menu-button-unselected:hover {
  background-color: rgba(251, 137, 16, 0.072);
}

.menu-icon-btn-img-selected {
  padding-left: 5px;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.menu-icon-btn-img-unselected {
  padding-left: 5px;
  width: 17px;
  height: 17px;
  filter: invert(0.6);
  cursor: pointer;
}

.menu-btn-title-selected {
  width: 100%;
  text-align: start;
  color: aliceblue;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;
}

.menu-btn-title-unselected {
  width: 100%;
  text-align: start;
  color: rgb(70, 70, 70);
  padding-left: 20px;
  font-size: 12px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;
}
