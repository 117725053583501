.snack-ribbon.invalid {
  height: 3%;
  width: 30%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 23, 23, 0.735),
    rgba(255, 0, 0, 0.809)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.snack-ribbon.valid {
  height: 3%;
  width: 30%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    to right,
    rgba(23, 255, 93, 0.735),
    rgba(0, 255, 47, 0.809)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.snack-ribbon-label.invalid {
  color: white;
  font-weight: 500;
  font-size: 11px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.snack-ribbon-label.valid {
  color: rgb(39, 39, 39);
  font-weight: 500;
  font-size: 11px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@media (max-width: 760px) {
  .snack-ribbon.invalid {
    width: 50%;
  }
  .snack-ribbon.valid {
    width: 50%;
  }
}
