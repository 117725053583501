.Dashboard {
  height: 100vh;
  width: 100vw;
  background-color: rgb(249, 251, 250);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  user-select: none;
}

.dash-left-panel {
  width: 15%;
  height: 100%;
  background-color: rgb(249, 251, 250);
  border-right: 1px solid rgb(214, 223, 231);
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)),
  url("/src/assets/authBg.jpg");
  background-size: cover;
  background-position-x: center;
  background-position-y: top; */
}

.dash-right-panel {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dash-top-panel {
  height: 8%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: whitesmoke;
  border-bottom: 1px solid rgb(214, 223, 231);
}

.dash-top-panel-left {
  width: 0;
  visibility: hidden;
}

.dash-top-panel-right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: whitesmoke;
}

.dash-top-namecard {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.namecard-head {
  font-size: 13px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.namecard-sub {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.namecard-sub-role {
  font-size: 10px;
  color: rgb(71, 71, 71);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.dash-right-content {
  height: 92%;
  width: 100%;
  background-color: white;
}

@media (max-width: 1150px) {
  .dash-right-panel {
    width: 100%;
  }
  .dash-left-panel {
    width: 0;
    border: none;
    visibility: hidden;
  }
  .dash-top-panel-right {
    width: 50%;
    height: 100%;
  }
  .dash-top-panel-left {
    width: 50%;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    overflow: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;
    overscroll-behavior: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: whitesmoke;
    visibility: visible;
  }

  .dash-top-panel-left::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .dash-top-panel-left {
    scrollbar-width: none;
  }

  .dash-top-panel-left::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .dash-top-panel-left::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
