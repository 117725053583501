.AccessDenied {
  height: 100vh;
  width: 100vw;
  background-color: bisque;
  user-select: none;
}

.access-den-cont {
  height: 100%;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.acc-den-title {
  margin: 0;
  color: rgb(80, 80, 80);
  font-size: 15px;
  font-weight: 800;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.acc-den-owner-note {
  margin: 0;
  color: rgb(80, 80, 80);
  font-size: 13px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.acc-den-others-note {
  margin: 0;
  color: rgb(80, 80, 80);
  font-size: 13px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.acc-den-img {
  height: 45px;
  width: 45px;
}