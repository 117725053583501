.TimeSlots {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.TimeSlots-cont {
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.TimeSlots-listview {
  height: 70%;
}

.TimeSlots-list-adapter {
  padding-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.TimeSlots-list-adapter-index {
  width: 3%;
  font-size: 8px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.TimeSlots-list-adapter-card {
  width: 97%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.TimeSlots-list-adapter-title {
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}