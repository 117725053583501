.AddFood {
  height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.AddFood-tools {
  position: relative;
  width: 94%;
  height: 50px;
  border-bottom: 1px solid rgb(214, 223, 231);
  transition: height 0.2s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
}

.AddFood-tools-hide {
  position: relative;
  width: 94%;
  height: 0;
  border-bottom: 1px solid rgb(214, 223, 231);
  transition: height 0.2s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
  overflow: hidden;
}

.AddFood-toast {
  width: 50%;
  left: 0;
}

.AddFood-content {
  padding: 2%;
  position: relative;
  height: 96%;
  width: 96%;
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.AddFood-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.AddFood-content::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.AddFood-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.AddFood-container-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.AddFood-container-column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.AddFood-label {
  color: rgb(80, 80, 80);
  font-size: 13px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.AddFood-label-sub {
  color: rgb(110, 110, 110);
  font-size: 12px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.AddFood-container {
  width: 100%;
  border: 1px solid rgb(214, 223, 231);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.AddFood-in-container {
  display: flex;
  flex-direction: row;
}

.AddFood-sublabel {
  color: rgb(75, 75, 75);
  font-size: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Addfood-horlist-item-adapter {
  height: 75%;
  border: 1px solid rgb(214, 223, 231);
  border-radius: 4px;
  padding: 10px;
  width: 200px;
  display: flex;
  flex-direction: column;
}

.AddFood-var-tool {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid rgb(214, 223, 231);
  height: 50px;
  width: 100%;
  background-color: rgb(250, 250, 250);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}