.TimePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
}

.TimePicker-wrapper {
  background-color: none;
  height: 25px;
  width: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.TimePicker-hour, .TimePicker-minute {
  background-color: rgb(241, 241, 241);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: none;
  height: 100%;
  width: 100%;
  caret-color: transparent;
  text-align: center;
  color: rgb(104, 104, 104);
  font-size: 12px;
  font-weight: 500;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.TimePicker-hour:hover, .TimePicker-minute:hover {
  caret-color: transparent;
  color: white;
  background-color: rgb(0, 136, 255);
}

.TimePicker-hour::placeholder, .TimePicker-minute::placeholder {
  font-size: 10px;
  color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.TimePicker-label {
  pointer-events: none;
  background: transparent;
  position: absolute;
  text-align: center;
  font-size: 12px;
  color: rgb(104, 104, 104);
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.TimePicker-controls {
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
}

.TimePicker-cont-inc {
  background-color: rgb(255, 255, 255);
  height: 50%;
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0.1px solid rgb(80, 80, 80);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top-right-radius: 4px;
}

.TimePicker-cont-inc:hover {
  background-color: rgb(255, 250, 96);
}

.TimePicker-cont-dec {
  background-color: rgb(255, 255, 255);
  height: 50%;
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0.1px solid rgb(80, 80, 80);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom-right-radius: 4px;
}

.TimePicker-cont-dec:hover {
  background-color: rgb(255, 250, 96);
}

.TimePicker-controls-img {
  height: 50%;
  width: 50%;
  position: relative;
}

.TimePicker-divider {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  color: rgb(80, 80, 80);
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
