.Auth {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)),
    url("/src/assets/authBg.jpg");
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  overflow: hidden;
  user-select: none;
}

.contentContainer {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.toiLogo {
  width: 145px;
}

.bottomTip {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.bottomTipText,
.bottomTipLink {
  color: gold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.bottomTipText {
  font-size: 13px;
  text-align: center;
  color: gainsboro;
}

.bottomTipLink {
  text-decoration: none;
  font-size: 13px;
}

.title {
  height: 10%;
  color: rgb(228, 140, 0);
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.fieldBtnGroup {
  width: 80%;
  height: 23%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: var('--align');
}

@media (max-height: 600px) {
  .toiLogo {
    height: 35%;
    width: auto;
  }

  @media (max-height: 500px) {
    .title {
      visibility: hidden;
    }
  }
}

@media (min-height: 900px) {
  .fieldBtnGroup {
    height: 20%;
  }
}

.rowauth {
  display: flex;
  flex-direction: row;
}