.button {
  background-color: var(--color);
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8.58px;
  padding-bottom: 8.58px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.button:hover {
  background-color: var(--color);
  filter: saturate(50%);
}

.btn-loading-spinner {
  width: 10px;
  height: 10px;
  border: 2px solid #ffe600;
  border-top-color: var(--color);
  border-bottom-color: var(--color);
  border-radius: 50%;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button-nopad {
  background-color: var(--color);
  color: white;
  height: calc(var(--inpad) + var(--inpad));
  padding-left: var(--inpad);
  padding-right: var(--inpad);
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.button-nopad:hover {
  background-color: var(--color);
  filter: saturate(50%);
  cursor: pointer;
  user-select: none;
}

.button-label {
  font-size: calc(var(--inpad) * 1.1);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;
  user-select: none;
}

.button-label2 {
  font-size: 10px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  cursor: pointer;
  user-select: none;
}
