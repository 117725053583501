.Loading {
  height: 100vh;
  width: 100vw;
  background-color: rgb(249, 251, 250);
  user-select: none;
}

.cupertino-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.cupertino-spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #007aff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; 
}

.loading-title {
  text-align: center;
  font-size: 12px;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}